 /*Change this one*/
 .search-label {
    flex: auto;
 }
 
 .background {
    width: 100%;
    height: 100%;
    position: absolute;
    right:0;
    padding-left: 15px;
    padding-right:15px;
  }

  @media (max-width: 992px){
  .card-view-enter {
    transform: translate(100%);
    opacity: 0;
  }
  .card-view-enter-active {
    transform: translate(0%);
    opacity: 1;
    transition: all 300ms ease-in-out;
    position:'relative';
  }
  .card-view-exit {
    transform: translate(0%);
    opacity:1;
  }
  .card-view-exit-active {
    transform: translate(-100%);
    opacity:0.5;
    transition: all 300ms ease-in-out;
  }
  
  .card-view-back-enter {
    transform: translate(-100%);
    opacity:0.5;
  }
  .card-view-back-enter-active {
    transform: translate(0%);
    opacity: 1;
    transition: all 300ms ease-in-out;
    position:'relative'
  }
  .card-view-back-exit {
    transform: translate(0%);
    opacity:1;
  }
  .card-view-back-exit-active {
    transform: translate(100%);
    opacity:0.5;
    transition: all 300ms ease-in-out;
  }
}