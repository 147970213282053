.map-container{
    width: 100%; 
    position: relative;
}

.map-background{
    position: absolute; 
    top: 0; 
    left: 0; 
    bottom: 0; 
    right: 0;  
    background-size: contain; 
    background-repeat:  no-repeat; 
    background-position: center center;
}