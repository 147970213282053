.be-empty {
    position: relative;
  }
  
.be-empty:after {
position: absolute;
display: block;
visibility: hidden;
opacity: 0;
content: "";
top: 0;
left: 0;
height: 100%;
width: 100%;
background: rgba(255, 255, 255, 0.85);
transition: all 0.2s ease;
z-index: 2;
}

.be-empty .be-message {
display: none;
}

.be-empty-active:after {
visibility: visible;
opacity: 1;
}

.be-empty-active .be-message {
display: block;
}

.be-empty .be-message {
position: absolute;
top: 50%;
right: 50%;
margin-top: -20px;
margin-right: -80px;
z-index: 3;
}
