.be-error {
    position: relative;
  }
  
.be-error:after {
position: absolute;
display: block;
visibility: hidden;
opacity: 0;
content: "";
top: 0;
left: 0;
height: 100%;
width: 100%;
background: rgba(255, 255, 255, 0.85);
transition: all 0.2s ease;
z-index: 2;
}

.be-error .be-message-error {
display: none;
}

.be-error-active:after {
visibility: visible;
opacity: 1;
}

.be-error-active .be-message-error {
display: block;
}

.be-error .be-message-error {
position: absolute;
top: 50%;
right: 50%;
margin-top: -60px;
margin-right: -50px;
z-index: 3;
}
